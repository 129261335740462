.tcd-table {
  max-width: 1200px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.01) 0px 0px 1px, rgba(0, 0, 0, 0.04) 0px 4px 8px, rgba(0, 0, 0, 0.04) 0px 16px 24px, rgba(0, 0, 0, 0.01) 0px 24px 32px;
  border-radius: 12px;
  border: 1px solid rgb(210, 217, 238);
  border-spacing: 0px;
  font-size: 14px;
  margin: 0 auto;
}

thead {
  background: #f1f1f1;
}


thead tr td {
  font-weight: 500;
  height: 30px;
}

.tcd-cell {
  padding: 5px 5px;
  text-align: center;
}

.tcd-header-name {
  min-width: 230px;
}

.tcd-header-price {
  min-width: 150px;
}

.tcd-header-change {
  min-width: 73px;
}

.tcd-header-vol {
  min-width: 98px;
}

.tcd-header-cap {
  min-width: 70px;
}

.tcd-header-supply {
  min-width: 101px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.center-content {
  display: flex;
  justify-content: left;
  align-items: center;
}

.tcd-token-icon {
  width: 24px;
  height: 24px;
}

.tcd-token-name {
  text-align: left;
  font-weight: 700;
  text-wrap: nowrap;
}

.tcd-token-symbol{
  margin-left: 5px;
  color: rgb(119, 136, 153);
}

span.color-red {
  color: red;
}

span.color-green {
  color: rgb(22, 199, 132);
}

.p-r-5 {
  margin-right: 5px;
}

@keyframes place-holder-animation {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0.6;
  }

  50% {
    opacity: 0.25;
  }

  75% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

.tcd-placeholder-cell {
  height: 24px;
  max-width: 100%;
  min-width: 50px;
  background: rgb(0 0 0 / 7%);
  border-radius: 50rem;
  animation: place-holder-animation 2s infinite;
}

.tcd-button-top-container {
  margin-bottom: 20px;
}

.tcd-button-top {
  padding: 10px 15px;
  border: none;
  margin-right: 10px;
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.01) 0px 0px 1px, rgba(0, 0, 0, 0.04) 0px 4px 8px, rgba(0, 0, 0, 0.04) 0px 16px 24px, rgba(0, 0, 0, 0.01) 0px 24px 32px;
  border-radius: 12px;
  cursor: pointer;
}

.tcd-button-top.active {
  background: rgb(232, 236, 251);
}

@media screen and (min-width: 1200px) {
  .tcd-table {
    width: 1200px;
  }
}

@media screen and (max-width: 768px) {
  .mobile-hide {
    display: none;
  }

  .tcd-table {
    width: 100%;
    border: none;
    box-shadow: none;
    border-radius: 0;
  }

  .tcd-header-price, 
  .tcd-header-name {
    min-width: unset;
  }
}

