

.App {
  min-height: 100vh;
  background-image: linear-gradient(180deg, rgb(253, 220, 220), rgb(242, 242, 242));
}

.tcd-main-content {
  max-width: 1200px;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .tcd-main-content {
    margin-bottom: 60px;
  }
}