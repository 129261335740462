header {
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
}

.logo{
  width: 60px;
  height: 60px;
  padding-right: 20px;
}

.eth{
  width: 20px;
  height: 20px;
  padding-right: 10px;

}

.link {
  color: #222222;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
}

.link:hover {
  background: #ffffff;
}

a.link.active {
  background: hsl(227, 70%, 95%);
}

.tcd-main-menu {
  display: flex;
  align-items: center;
}

.tcd-header-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.tcd-chains {
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  font-weight: 500;
  transition: 0.3s;
  display: flex;
  align-items: center;
}

.tcd-chains:hover {
  background-color: #ffffff;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .tcd-main-menu.tcd-menu-fixed--bottom {
    position: fixed;
    bottom: 0;
    background: #ffffff;
    width: 100%;
    left: 0;
    border-top: 1px solid rgb(210, 217, 238);
    z-index: 99;
  }

  .tcd-menu-fixed--bottom a {
    padding: 20px 15px;
    display: flex;
    flex: 1;
    justify-content: center;
  }
}
